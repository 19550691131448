:root {
  --terra-base-background-color: transparent;
  --terra-base-color: inherit;
  --terra-base-font-family: inherit;
  --terra-base-font-size: inherit;
  --terra-base-html-height: inherit;
  --terra-base-html-margin: inherit;
  --terra-base-body-height: inherit;
  --terra-base-line-height: inherit;
  --terra-base-body-margin: inherit;
}
