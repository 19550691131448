:local {
  .select input,
  .select input:focus {
    background-color: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
    margin: 0.21428571428571427rem 0 0 !important;
    outline: none !important;
  }

  .placeholder {
    color: var(--terra-form-select-help-text-color, rgba(28, 31, 33, 0.63));
    font-size: var(--terra-form-select-help-text-font-size, 0.857rem);
    font-weight: var(--terra-form-select-help-text-font-weight, normal);
    line-height: 1.25;
    margin-top: var(--terra-form-select-help-text-margin-top, 0.357em);
    text-align: left;
  }

  .hidden-select {
    display: none;
  }
}
